
import { defineComponent, reactive, toRefs, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default defineComponent({
	name: 'export-customers-modal',
	components: {},
	setup() {
		const formRef = ref<null | HTMLFormElement>(null);
		const loading = ref<boolean>(false);
		const state = reactive({
			shortcuts: [
				{
					text: 'Last week',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end];
					}
				},
				{
					text: 'Last month',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end];
					}
				},
				{
					text: 'Last 3 months',
					value: () => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end];
					}
				}
			]
		});

		const formData = ref({
			dateRange: [],
			exportFormat: '',
			paymentType: ''
		});

		const rules = ref({
			dateRange: [
				{
					required: true,
					message: 'Date range is required',
					trigger: 'change'
				}
			]
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;

					setTimeout(() => {
						loading.value = false;

						Swal.fire({
							text: 'Form has been successfully submitted!',
							icon: 'success',
							buttonsStyling: false,
							confirmButtonText: 'Ok, got it!',
							customClass: {
								confirmButton: 'btn btn-primary'
							}
						}).then(() => {
							window.location.reload();
						});
					}, 2000);
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			...toRefs(state),
			formData,
			rules,
			submit,
			formRef,
			loading
		};
	}
});
