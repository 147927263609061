
import { defineComponent, ref, onMounted } from 'vue';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ExportCustomerModal from '@/components/modals/forms/ExportCustomerModal.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { ICustomer } from '@/core/data/customers';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default defineComponent({
	name: 'customers-listing',
	components: {
		Datatable,
		ExportCustomerModal
	},
	setup() {
		const checkedCustomers = ref([]);
		const tableHeader = ref([
			{
				key: 'checkbox',
				sortable: false
			},
			{
				name: 'Customer Name',
				key: 'name',
				sortable: true
			},
			{
				name: 'Email',
				key: 'emailFrom',
				sortable: true
			},
			{
				name: 'Website',
				key: 'websiteUrl',
				sortable: true
			},
			{
				name: 'Actions',
				key: 'actions'
			}
		]);

		const tableData = ref<Array<ICustomer>>([]);
		const initCustomers = ref<Array<ICustomer>>([]);

		const getCustomersList = () => {
			ApiService.get('Customer')
				.then(({ data }) => {
					tableData.value.splice(0, tableData.value.length, ...data);
					initCustomers.value.splice(0, tableData.value.length, ...data);
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs('Customers', []);
			getCustomersList();
		});

		const deleteFewCustomers = () => {
			checkedCustomers.value.forEach(item => {
				deleteData(item);
			});
			checkedCustomers.value.length = 0;
		};

		const deleteCustomer = id => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					deleteData(id);
				}
			});
		};

		const deleteData = id => {
			for (let i = 0; i < tableData.value.length; i++) {
				if (tableData.value[i].id === id) {
					tableData.value.splice(i, 1);
				}
			}
		};

		const search = ref<string>('');
		const searchItems = () => {
			tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
			if (search.value !== '') {
				let results: Array<ICustomer> = [];
				for (let j = 0; j < tableData.value.length; j++) {
					if (searchingFunc(tableData.value[j], search.value)) {
						results.push(tableData.value[j]);
					}
				}
				tableData.value.splice(0, tableData.value.length, ...results);
			}
		};

		const searchingFunc = (obj, value): boolean => {
			for (let key in obj) {
				if (
					!Number.isInteger(obj[key]) &&
					!(typeof obj[key] === 'object') &&
					!(typeof obj[key] === 'boolean')
				) {
					if (obj[key].indexOf(value) != -1) {
						return true;
					}
				}
			}
			return false;
		};

		return {
			tableData,
			tableHeader,
			deleteCustomer,
			getCustomersList,
			search,
			searchItems,
			checkedCustomers,
			deleteFewCustomers
		};
	}
});
